import { Trans, useTranslation } from 'react-i18next'
import { ResponsiveDrawer } from '../ResponsiveDrawer'
import { Input } from '../ui/input'
import { useState } from 'react'
import { db } from '@/services/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button } from '../ui/button'
import { Search } from 'lucide-react'

export type UnsplashImageResult = {
    id: string
    urls: {
        small: string
        full: string
        thumb: string
    },
    user: {
        name: string
        links: {
            html: string
        }
    }
}

export const ImageDrawer = (props: { open: boolean, onClose: () => void, onChange: (image: UnsplashImageResult) => void }) => {
    const { t } = useTranslation()
    const [q, setQ] = useState('')
    const [page, setPage] = useState(0)
    const [images, setImages] = useState<UnsplashImageResult[]>([])

    return <ResponsiveDrawer
        title={t('board-background-image-drawer-title')}
        description={t('board-background-image-drawer-description')}
        open={props.open}
        onClose={handleClose}>
        <form className="grid items-start gap-4" onSubmit={handleSubmit}>
            <div className="flex w-full items-center space-x-2">
                <Input
                    type="search"
                    id="q"
                    placeholder={t('board-background-image-drawer-search')}
                    value={q}
                    onChange={(e) => setQ(e.currentTarget.value)} />
                <Button type="submit"><Search size={16} /></Button>
            </div>
            <div className="h-[450px] overflow-x-hidden overflow-y-auto" id="scroller">
                <InfiniteScroll
                    scrollableTarget="scroller"
                    scrollThreshold={0.9}
                    dataLength={images.length}
                    next={fetchData}
                    className="columns-2 md:columns-3 gap-5"
                    hasMore={true}
                    loader={<></>}>
                    {images.map((image) =>
                        <img
                            key={image.id}
                            onClick={() => handleClose(image)}
                            className="aspect-video w-full mb-5 rounded-lg cursor-pointer"
                            src={image.urls.thumb} />
                    )}
                </InfiniteScroll>
            </div>
            <div className="text-sm text-gray-500">
                <Trans
                    t={t}
                    i18nKey="board-background-image-drawer-unsplash-credit"
                    components={{ a: <a /> }} />
            </div>
        </form>
    </ResponsiveDrawer>

    function handleClose(selectedImage?: UnsplashImageResult) {
        setQ('')
        setPage(1)
        setImages([])

        if (selectedImage) {
            props.onChange(selectedImage)
        }

        props.onClose()
    }

    async function fetchData() {
        if (!q) {
            setImages([])
            setPage(0)
            return
        }

        const { data, error } = await db.functions.invoke('unsplash', { body: { q, page: page + 1 } })
        if (error) {
            console.error(error)
            return
        }

        console.log(images, data.results)

        setImages(i => [...i, ...data.results])
        setPage(p => p + 1)
    }

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault()
        e.stopPropagation()

        setPage(0)
        setImages([])

        await fetchData()
    }
}