import './App.css'
import { useUser } from '../../state/user'
import { Header } from '../Header'
import { TaskForm } from '../TaskForm'
import { ProjectForm } from '../ProjectForm'
import { BoardForm } from '../BoardForm'
import { BoardsEmptyState } from '../BoardsEmptyState'
import { useTaskForm } from '@/hooks/useTaskForm'
import { useProjectForm } from '@/hooks/useProjectForm'
import { useRef } from 'react'
import { Outlet } from '@tanstack/react-router'
import { useBoardsStore } from '@/state/boards'

export const App = () => {
    const userId = useUser(({user}) => user?.id)
    const hasAccess = useUser(({profile}) => profile?.has_access)
    const containerRef = useRef<HTMLDivElement>(null)
    const boardWallpaper = useBoardsStore(({selectedBoardId: id, boards}) => id ? boards.find(board => board.id === id)?.background_image_url : null)
    const boardWallpaperColor = useBoardsStore(({selectedBoardId: id, boards}) => id ? boards.find(board => board.id === id)?.background_color : null)

    const showLayout = userId && hasAccess
    
    return <>
            <div ref={containerRef} className="app-container relative relative">
            {showLayout && <Header/>}
            
            <Outlet/>

            {showLayout && <BoardsEmptyState/>}
            <Forms/>

            <FadeOut/>
        </div>

        {(boardWallpaper || boardWallpaperColor) && <>
            <div className="inset-0 fixed bg-white/50 dark:bg-black/70 z-[-1]"/>
            <div className="grain fixed inset-0 z-[-2] opacity-60"/>
            <div 
                className="fixed inset-0 z-[-3] bg-center bg-cover" 
                style={{ backgroundImage: `url(${boardWallpaper})`, backgroundColor: boardWallpaperColor || ''}}/>
        </>}
    </>
}

// Neat little trick to create a fade out effect when the user scrolls down, got this from https://natt.sh/blog.
const FadeOut = () => {
    return <div className="pointer-events-none fixed bottom-0 isolate z-10 h-5 md:h-16 w-full">
        <div className="mask-t absolute inset-0 backdrop-blur-[1px]"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[2px] hidden md:block"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[3px] hidden md:block"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[5px] hidden md:block"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[8px] hidden md:block"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[10px]"></div>
    </div>
}

const Forms = () => {
    const { shouldMount: shouldTaskFormMount } = useTaskForm(({ shouldMount }) => ({ shouldMount }))
    const { shouldMount: shouldProjectFormMount } = useProjectForm(({ shouldMount }) => ({ shouldMount }))

    return <>
        {shouldTaskFormMount && <TaskForm/>}
        {shouldProjectFormMount && <ProjectForm/>}
        <BoardForm/>
    </>
}